import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Post from '../templates/Post';
import Meta from '../components/Meta';
import Layout from '../components/Layout';
import Interstitial from '../components/Interstitial';
import Disclaimer from '../components/Disclaimer';

const WhatsAppNoieuropei = ({ data, location }) => (
  <Layout location={location}>
    <Meta
      title="Salvare il nostro futuro! Come votare nelle elezioni UE  🗳"
      description="Cliccare il link per mandare un messaggio ai tuoi amici su WhatsApp e dare una voce anche a loro!  ➡️"
      shareImage="italiano"
      location={location}
      site={get(data, 'site.meta')}
      whatsAppMessage={`Ciao! Sei un cittadino italiano e vivi in UK?
Abbiamo il diritto di votare nelle elezioni europee anche mentre siamo qui!

*IMPORTANTE!!!*
💥 Manda questo messaggio ai tuoi amici europei che vivono in UK - questo paese e' anche casa nostra!  💥
Clicca qui: https://saveourfuture.uk/noieuropei

Ci sono 3 passi facilissimi per registrarsi per votare:
*Devi registrarti entro 7 maggio (o meglio... ora! ;-)):*

*1) Registrarsi per votare*
Devi soltanto fare questi passi semplici (3 minuti):
https://saveourfuture.uk/simple

(Scegli l'opzione 'postal vote' e puoi votare quando ti pare)

*CONDIVIDi!!!*
💥 Manda questo messaggio ai tuoi amici italiani in UK! 💥
Clicca qui: https://saveourfuture.uk/noieuropei

🗣 *QUESTA VOLTA FACCIAMO SENTIRE LE NOSTRE VOCI!!!* 🗣`}
    />
    <Interstitial>
      <p>Scegli cinque migliori amici o gruppi per inviare il tuo messaggio Whatsapp</p>
      <p>Generare messaggio ora</p>
      <p>Puoi personalizzare il messaggio nel passaggio successivo ...</p>
    </Interstitial>
    <Disclaimer />
  </Layout>
);

WhatsAppNoieuropei.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default WhatsAppNoieuropei;

export const query = graphql`
  query NoieuropeiQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
        adsense
      }
    }
  }
`;
